import AppSerializer from "serializers/app";

class ReferralPointSerializer extends AppSerializer {
  constructor(store, props) {
    super(store, props);
  }

  get attrs() {
    return {
      kangarooPoints: false,
    };
  }
}

export default ReferralPointSerializer;
