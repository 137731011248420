import AppAdapter from "adapters/app";
import Axios from "utils/axios";

class ReferralPointsAdapter extends AppAdapter {
  constructor(props) {
    super(props);
  }

  static userId = "";

  // Overrides
  static urlForQueryRecord(id) {
    return this.buildURL() + "/kangaroo/rewardPoints/" + id;
  }

  // Overrides
  static async queryRecord(modelName, id = null, params = {}) {
    try {
      let url = this.urlForQueryRecord(id);
      let axios = new Axios().instance();
      let response = await axios.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default ReferralPointsAdapter;
