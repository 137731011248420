import React, { Fragment, useEffect, useState } from "react";
import { withStore } from "contexts/store-context";
import { withToast } from "contexts/toast-context";
import { withSession } from "contexts/session-context";
import PromoForm from "components/promo/promo-form";
import { BasicButton } from "components/bootstrap/button-wrapper";
import { LoadingSkeleton } from "components/loading/loading-skeleton";
import {
  SectionBlock,
  SectionTitle,
  SectionBody,
  SectionFooter,
} from "components/blocks/section-blocks";
import { Row, Col, FormGroup, Label, Input, Modal, Button } from "reactstrap";
import { setProp, setRelation } from "utils/store-helpers";

const PromoSelect = (props) => {
  const { type = "form", booking, session, store, toast } = props;
  const [loading, setLoading] = useState(false);
  const [promos, setPromos] = useState([]);
  const [modal, toggleModal] = useState(false);

  // Hooks
  useEffect(() => {
    store.adapterFor("promo").set("userId", session.userId);
    fetchData();
    return () => {
      store.adapterFor("promo").set("userId", null);
    };
  }, []);

  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let promos = await store.query("promo", {
        status: "active",
        include: "promoCode",
      });

      setPromos(promos);
      setPromos(promos);
      setActivePromo(promos);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  };

  console.log("all promos", promos);

  const setActivePromo = (promos) => {
    let promo = promos.find((promo) => promo.status == "active");
    promo && setProp(booking, "promo", promo);
  };

  const selectPromo = (promoId, form = false) => {
    console.log("promoId", promoId);
    let promo = store.peekRecord("promo", promoId);

    console.log("check promo", promo);

    setProp(booking, "promo", promo);
    // remove applied referral points
    booking.set("hasKangarooDiscount", "");
    booking.set("kangarooDiscount", "");

    if (form) {
      toggleModal(!modal);
      fetchData();
    }
  };

  console.log("promos", promos);

  // Render
  if (loading) {
    return <LoadingSkeleton type={type} />;
  }
  return (
    <Fragment>
      <Modal isOpen={modal}>
        <PromoForm
          user={session.user}
          modal={modal}
          toggleModal={() => toggleModal(!modal)}
          nextAction={(promo) => selectPromo(promo, true)}
        />
      </Modal>
      <FormGroup>
        <Input
          type="select"
          value={booking?.promo?.id ?? ""}
          placeholder="Select promo code"
          onChange={(promo) => selectPromo(promo.target.value)}
        >
          <option value="">Select promo code</option>
          {promos.map((promo) => (
            <option key={promo.id} value={promo.id}>
              {promo.code} • {promo.displayAmount + " off"}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <BasicButton title="Add Promo" onClick={() => toggleModal(!modal)} />
      </FormGroup>
    </Fragment>
  );
};

export default withStore(withToast(withSession(PromoSelect)));
