import AppModel from "models/app";
import phoneFormatter from "phone-formatter";

class User extends AppModel {
  constructor(type, store, props = {}) {
    super(type, store, props);

    // Attrs
    this.name = props.name;
    this.email = props.email;
    this.password = props.password;
    this.phone = props.phone;
    this.photo = props.photo;
    this.passwordPresent = props.passwordPresent;
    this.googlePresent = props.googlePresent;
    this.facebookPresent = props.facebookPresent;
    this.referralLink = props.referralLink;
    this.kangarooPoints = props.kangarooPoints;

    // Keys
    this.providerId = props.providerId;

    // Relationships
    this.location = this.belongsTo("location", props.location);
    this.settings = this.belongsTo("user-setting", props.settings);
    this.cards = this.hasMany("provider", props.cards);
  }

  // Computed
  get displayPhone() {
    return this.phone
      ? phoneFormatter.format(this.phone, "(NNN) NNN-NNNN")
      : "";
  }

  get firstName() {
    return this.name && this.name.split(" ")[0];
  }

  get lastName() {
    return this.name && this.name.split(" ")[1];
  }

  get url() {
    return `/user/${this.id}`;
  }

  get settingsUrl() {
    return `/user/${this.id}/settings`;
  }

  get displayRefferalLink() {
    if (this.referralLink) {
      const regex = /[?&]ref=([^&]+)/;
      const match = this.referralLink?.match(regex);
      if (match) {
        return match?.[1];
      }
    }
    return "";
  }
}

export default User;
