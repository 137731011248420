import React, { Component } from "react";
import adapterFor from "store/adapter-for";
import serializerFor from "store/serializer-for";
import modelFor from "store/model-for";
import { addObject, removeObject, isEmpty, logger } from "utils/helpers";
import JsonApiError from "utils/json-api-error";

export const StoreContext = React.createContext();

class StoreProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adapterFor: this.adapterFor.bind(this),
      modelFor: this.modelFor.bind(this),
      serializerFor: this.serializerFor.bind(this),
      createRecord: this.createRecord.bind(this),
      pushAll: this.pushAll.bind(this),
      pushRecord: this.pushRecord.bind(this),
      peekAll: this.peekAll.bind(this),
      peekRecord: this.peekRecord.bind(this),
      peekOrCreateRecord: this.peekOrCreateRecord.bind(this),
      updateRecord: this.updateRecord.bind(this),
      updateStore: this.updateStore.bind(this),
      findAll: this.findAll.bind(this),
      findRecord: this.findRecord.bind(this),
      query: this.query.bind(this),
      queryRecord: this.queryRecord.bind(this),
      apiRequest: this.apiRequest.bind(this),
      removeAll: this.removeAll.bind(this),
      removeRecord: this.removeRecord.bind(this),
    };
  }

  // Methods
  adapterFor(modelName) {
    return adapterFor(modelName, this.state);
  }

  modelFor(modelName, data) {
    return modelFor(modelName, this.state, data);
  }

  serializerFor(modelName, data) {
    return serializerFor(modelName, this.state, data);
  }

  peekAll(modelName) {
    let models = this.state[modelName] || [];
    return models;
  }

  peekRecord(modelName, recordID) {
    let models = this.state[modelName] || [];
    return models.find((model) => model.id == recordID);
  }

  peekOrCreateRecord(modelName, record) {
    if (modelName === "schedule-date") {
      //Data doesnt reflect in calendar instantly so thats why this condition
      return this.createRecord(modelName, record);
    } else {
      let models = this.state[modelName] || [];
      let storeRecord = this.peekRecord(modelName, record.id);
      return storeRecord ? storeRecord : this.createRecord(modelName, record);
    }
  }

  createRecord(modelName, data) {
    let record = modelFor(modelName, this.state, data);
    return this.pushRecord(modelName, record);
  }

  updateRecord(modelName, storeRecord, record) {
    const state = this.state;
    this.removeRecord(modelName, storeRecord);
    let newRecord = this.createRecord(modelName, record);
    return newRecord;
  }

  updateStore(modelName) {
    const state = this.state;
    let models = this.state[modelName] || [];
    this.setState({ [modelName]: models });
    logger("Store: ", this.state);
    return true;
  }

  pushAll(modelName, records) {
    const state = this.state;
    let models = this.state[modelName] || [];
    this.removeAll(modelName);
    let newRecords = records.map((record) => {
      return this.createRecord(modelName, record);
    });
    return newRecords;
  }

  pushRecord(modelName, record) {
    const state = this.state;
    let models = this.state[modelName] || [];
    models.push(record);
    this.setState({ [modelName]: models });
    // logger('Store: ', this.state);
    return record;
  }

  async findAll(modelName, params) {
    try {
      let storeRecords = this.state[modelName] || [];
      if (!isEmpty(storeRecords)) {
        return storeRecords;
      }
      // Fetch All
      let response = await this.adapterFor(modelName).findAll(
        modelName,
        params
      );
      let records = this.serializerFor(modelName).normalizeArray(
        response.data,
        response.included,
        response.meta
      );
      logger("Server Response: ", records);
      let models = this.pushAll(modelName, records.records);
      models.meta = records.meta;
      logger("Store: ", this.state);
      return models;
    } catch (e) {
      throw JsonApiError.formatErrors(e);
    }
  }

  async findRecord(modelName, recordID, params) {
    try {
      let storeRecord = this.peekRecord(modelName, recordID);
      if (storeRecord) {
        return storeRecord;
      }
      // Fetch Record
      let response = await this.adapterFor(modelName).findRecord(
        modelName,
        recordID,
        params
      );
      let record = this.serializerFor(modelName).normalize(
        response.data,
        response.included
      );
      logger("Server Response: ", record);
      let model = this.createRecord(modelName, record);
      logger("Store: ", this.state);
      return model;
    } catch (e) {
      throw JsonApiError.formatErrors(e);
    }
  }

  async query(modelName, params) {
    try {
      let response = await this.adapterFor(modelName).query(modelName, params);
      let records = this.serializerFor(modelName).normalizeArray(
        response.data,
        response.included,
        response.meta
      );
      logger("Server Response: ", records);
      this.removeAll("service");
      let models = this.pushAll(modelName, records.records);
      models.meta = records.meta;
      logger("Store: ", this.state);
      return models;
    } catch (e) {
      throw JsonApiError.formatErrors(e);
    }
  }

  async queryRecord(modelName, recordID, params, isProviderBooking = false) {
    try {
      let response = await this.adapterFor(modelName).queryRecord(
        modelName,
        recordID,
        params
      );
      let record = this.serializerFor(modelName).normalize(
        response.data,
        response.included
      );
      logger("Server Response: ", record);
      this.removeRecord(modelName, record.id);
      let model = this.createRecord(modelName, record);
      logger("Store: ", this.state);
      if (isProviderBooking) {
        const serviceList = model?.services || [];
        const updatedServiceList = record?.services || [];
        serviceList?.map((item) => {
          const currentService = updatedServiceList?.find(
            (s) => s?.id === item?.id
          );
          item.price = currentService?.price;
          item.quantity = currentService?.quantity;
          return item;
        });
        model.services = serviceList;
      }
      return model;
    } catch (e) {
      throw JsonApiError.formatErrors(e);
    }
  }

  async apiRequest(modelName, recordID, params) {
    try {
      let response = await this.adapterFor(modelName).queryRecord(
        modelName,
        recordID,
        params
      );
      let record = this.serializerFor(modelName).normalize(
        response.data,
        response.included
      );
      logger("Server Response: ", record);
      return record;
    } catch (e) {
      throw JsonApiError.formatErrors(e);
    }
  }

  removeAll(modelName, records) {
    try {
      const state = this.state;
      state[modelName] = [];
      this.setState(state);
      logger("Store: ", state);
      return null;
    } catch (e) {}
  }

  removeRecord(modelName, record) {
    const state = this.state;
    let models = state[modelName] || [];
    let model = models.find((model) => model?.id == record?.id);
    models = removeObject(models, model);
    this.setState(state);
    // logger('Store: ', this.state);
    return null;
  }

  // Render
  render() {
    return (
      <StoreContext.Provider value={this.state}>
        {this.props.children}
      </StoreContext.Provider>
    );
  }
}

const withStore = function (WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <StoreContext.Consumer>
          {(context) => <WrappedComponent store={context} {...this.props} />}
        </StoreContext.Consumer>
      );
    }
  };
};

export { StoreProvider, withStore };
