const fontAwesome = require('@fortawesome/fontawesome-svg-core');

import {
	faAd,
	faAt,
	faAddressCard,
	faBells,
	faBolt,
	faBriefcase,
	faBinoculars,
	faBroadcastTower,
	faCalendar,
	faCalendarWeek,
	faCalendarAlt,
	faCalendarPlus,
	faCalendarTimes,
	faCamera,
	faChartPieAlt,
	faCheckCircle,
	faClock,
	faCookieBite,
	faCommentLines,
	faCreditCard,
	faCut,
	faExclamationTriangle,
	faFileCertificate,
	faIdBadge,
	faHandPointer,
	faImages,
	faKey,
	faLifeRing,
	faLock,
	faMapMarkerAltSlash,
	faMobile,
	faMoneyBillWave,
	faNewspaper,
	faPaperPlane,
	faPiggyBank,
	faShieldCheck,
	faShoppingCart,
	faSlidersH,
	faSms,
	faStars,
	faStarExclamation,
	faTags,
	faTrash,
	faUniversity,
	faUser,
	faUserCircle,
	faUserFriends,
	faUserTag,
	faWandMagic,
	faTimes,
	faCopy
} from '@fortawesome/pro-duotone-svg-icons';

fontAwesome.library.add(
	faAd,
	faAt,
	faAddressCard,
	faBells,
	faBolt,
	faBriefcase,
	faBinoculars,
	faBroadcastTower,
	faCalendar,
	faCalendarWeek,
	faCalendarAlt,
	faCalendarPlus,
	faCalendarTimes,
	faCamera,
	faChartPieAlt,
	faCheckCircle,
	faClock,
	faCookieBite,
	faCommentLines,
	faCreditCard,
	faCut,
	faExclamationTriangle,
	faFileCertificate,
	faHandPointer,
	faIdBadge,
	faImages,
	faKey,
	faLifeRing,
	faLock,
	faMapMarkerAltSlash,
	faMobile,
	faMoneyBillWave,
	faNewspaper,
	faPaperPlane,
	faPiggyBank,
	faShieldCheck,
	faShoppingCart,
	faSlidersH,
	faSms,
	faStars,
	faStarExclamation,
	faTags,
	faTrash,
	faUniversity,
	faUser,
	faUserCircle,
	faUserFriends,
	faUserTag,
	faWandMagic,
	faTimes,
	faCopy
)

export default fontAwesome;
