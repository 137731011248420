import React from "react";
import FAWrapper from "components/vendors/fa-wrapper";
import { Button, Badge, UncontrolledTooltip } from "reactstrap";

const DetailCard = (props) => {
  const {
    html,
    icon,
    photo,
    status,
    preTitle,
    title,
    line1,
    line2,
    line3,
    timestamp,
    className = "",
    children,
  } = props;

  const onCopyReferralLink = () => {
    navigator?.clipboard
      ?.writeText(props?.showReferralLink)
      .then(() => {
        props?.toast?.showSuccess("Link Copied.");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  return (
    <div className={"card detail " + className}>
      <div className={icon ? "card-header detail icon" : "card-header detail"}>
        {html && html}
        {icon && (
          <FAWrapper
            icon={icon}
            iconFam="fad"
            size="2x"
            className="card-header-icon"
          />
        )}
        {photo && <img className="card-header-img" src={photo} />}
      </div>
      <div className="card-body detail">
        {status && (
          <Badge color={status} className="mb-2" pill>
            {status}
          </Badge>
        )}
        {preTitle && preTitle}
        {title && <h5 className="title">{title}</h5>}
        {line1 && <h6>{line1}</h6>}
        {line2 && <h6>{line2}</h6>}
        {line3 && <h6>{line3}</h6>}
        {children}
      </div>
      {props?.showReferralLink ? (
        <div className="card-footer flex-column">
          {props?.showReferralPoint ? (
            <h6 className="italic gray-color card-word-break">
              Referral points : {props?.referralPoint}
            </h6>
          ) : null}
          <h6 className="italic gray-color card-word-break flex">
            Referral link
            <Button
              color="text-gray"
              onClick={() => onCopyReferralLink()}
              className="btn btn-nav-gray btn-link-copy"
            >
              <span id="tooltipLink">
                <FAWrapper icon="copy" className="accent-color" size="2px" />
              </span>
              <UncontrolledTooltip placement="top" target="tooltipLink">
                {props?.showReferralLink}
              </UncontrolledTooltip>
            </Button>
          </h6>
        </div>
      ) : null}
      {timestamp && (
        <div className="card-footer">
          <small className="italic gray-color">Last Updated: {timestamp}</small>
        </div>
      )}
    </div>
  );
};

export default DetailCard;
