import AppModel from "models/app";
import Accounting from "accounting";
import Moment from "moment-timezone";

import { sum, isEmpty } from "utils/helpers";

class Booking extends AppModel {
  constructor(type, store, props = {}) {
    super(type, store, props);

    // Attrs
    this.status = props.status;
    this.amount = props.amount;
    this.duration = props.duration;
    this.confirmationCode = props.confirmationCode;
    this.startDate = props.startDate;
    this.endDate = props.endDate;
    this.instructions = props.instructions;
    this.cancellationPolicy = props.cancellationPolicy;
    this.discount = props.discount;
    this.providerCommissionPercent = props?.providerCommissionPercent;
    this.providerCommissionAmount = props?.providerCommissionAmount;
    this.addressBasedTimezone = props?.addressBasedTimezone;

    this.hasKangarooDiscount = props?.hasKangarooDiscount;
    this.kangarooDiscount = props?.kangarooDiscount;

    // Computed
    this.travelFee = props.travelFee;
    this.commissionFee = props.commissionFee;
    this.platformFee = props.platformFee;
    this.stripeFee = props.stripeFee;
    this.subtotal = props.subtotal;
    this.total = props.total;
    this.tipAmount = props.tipAmount;
    this.refundAmount = props.refundAmount;
    this.totalServiceCount = props.totalServiceCount;
    this.isUpdate = false;
    // Relationships
    this.card = this.belongsTo("card", props.card);
    this.customer = this.belongsTo("customer", props.customer);
    this.promo = this.belongsTo("promo", props.promo);
    this.location = this.belongsTo("location", props.location);
    this.provider = this.belongsTo("provider", props.provider);
    this.user = this.belongsTo("user", props.user);
    this.review = this.belongsTo("review", props.review);
    this.charges = this.hasMany("charge", props.charges);
    this.refunds = this.hasMany("refund", props.refunds);
    this.services = this.hasMany("services", props.services);
    this.location = this.belongsTo("location", props.location);
  }

  // Computed
  get serviceIds() {
    return this.services.map((service) => service.id);
  }

  get photo() {
    return !isEmpty(this.services) ? this.services[0].photo : "";
  }

  get summary() {
    let count = this.services.length;
    return `${count}x Services`;
  }

  get totalProductQuantity() {
    let services = this.services || [];
    let quantity = 0;
    if (services && services?.length > 0) {
      quantity = services?.reduce((total, item) => total + item.quantity, 0);
    }
    return `${quantity}x Services`;
  }

  // Statuses
  get isDraft() {
    return this.status == "draft";
  }

  get isRequested() {
    return this.status == "requested";
  }

  get isAccepted() {
    return this.status == "accepted";
  }

  get isDenied() {
    return this.status == "denied";
  }

  get isFinished() {
    return this.status == "finished";
  }

  get isCanceled() {
    return this.status == "canceled";
  }

  // Displays
  get displayConfirmationCode() {
    return this.confirmationCode ? this.confirmationCode.toUpperCase() : "--";
  }

  get displayInstructions() {
    return this.instructions ? this.instructions : "--";
  }

  // Dates / Times
  get displayStartDate() {
    const utcMoment = Moment.utc(this.startDate);
    const mstMoment = utcMoment.tz(this?.addressBasedTimezone || "");
    const formattedDateString = mstMoment?.format("ddd MMM Do, YYYY");
    return this?.addressBasedTimezone && !this?.isUpdate
      ? formattedDateString
      : Moment(this.startDate).format("ddd MMM Do, YYYY");
    // return Moment(this.startDate).format("ddd MMM Do, YYYY");
  }

  get displayStartTime() {
    const utcMoment = Moment.utc(this.startDate);
    const mstMoment = utcMoment.tz(this?.addressBasedTimezone || "");
    const formattedDateString = mstMoment?.format("hh:mma");
    return this?.addressBasedTimezone && !this?.isUpdate
      ? formattedDateString
      : Moment(this.startDate).format("hh:mma");
    // return Moment(this.startDate).format("hh:mma");
  }

  get displayDateTime() {
    // return Moment(this.startDate).format("dddd MMM Do @ hh:mma")
    if (this.startDate) {
      const utcMoment = Moment.utc(this.startDate);
      const mstMoment = utcMoment.tz(this?.addressBasedTimezone || "");
      const formattedDateString = mstMoment?.format("dddd MMM Do @ hh:mma");
      return this?.addressBasedTimezone && !this?.isUpdate
        ? formattedDateString
        : Moment(this.startDate).format("dddd MMM Do @ hh:mma");
    } else {
      return null;
    }
  }

  get displayShortDateTime() {
    const utcMoment = Moment.utc(this.startDate);
    const mstMoment = utcMoment.tz(this?.addressBasedTimezone || "");
    const formattedDateString = mstMoment?.format("MMM Do @ h:mma");
    return this?.addressBasedTimezone && !this?.isUpdate
      ? formattedDateString
      : Moment(this.startDate).format("MMM Do @ h:mma");
    // return Moment(this.startDate).format("MMM Do @ h:mma");
  }

  get displayEndDate() {
    const utcMoment = Moment.utc(this.endDate);
    const mstMoment = utcMoment.tz(this?.addressBasedTimezone || "");
    const formattedDateString = mstMoment?.format("dddd MMM Do @ hh:mma");
    return this?.addressBasedTimezone && !this?.isUpdate
      ? formattedDateString
      : Moment(this.endDate).format("dddd MMM Do @ hh:mma");
    // return Moment(this.endDate).format("dddd MMM Do @ hh:mma");
  }

  // Previews
  get previewDuration() {
    let duration = sum(
      this.services.map((service) => parseFloat(service.duration))
    );
    let checkAddOnExist = this.services.some(
      (service) => service.group === "addOn"
    );

    if (checkAddOnExist) {
      duration = duration + 30;
    }

    return duration ? parseFloat(duration) : 0;
  }

  get previewAmount() {
    let amount = sum(this.services.map((service) => parseFloat(service.price)));
    return amount ? parseFloat(amount) : 0;
  }

  get previewCommissionFee() {
    let fees = this.previewAmount * 0.7;
    return parseFloat(fees);
  }

  get previewPlatformFee() {
    let fees = this.previewAmount * 0.3;
    return parseFloat(fees);
  }

  get previewTravelFee() {
    return parseFloat(this.previewAmount) * 0;
  }

  get previewDiscount() {
    if (this.hasKangarooDiscount) {
      return this.kangarooDiscount;
    } else if (this.promo?.promoType?.includes("percentage")) {
      // Percent Discount
      let calculatePercentValue =
        (this.previewAmount * this.promo.amount) / 100;

      return calculatePercentValue;
    } else {
      return !isEmpty(this.promo) ? this.promo.amount : 0;
    }
  }

  get previewSubtotal() {
    if (this.promo?.promoType?.includes("percentage")) {
      // Percent Discount

      if (this.previewDiscount) {
        let finalAmount = this.previewAmount - this.previewDiscount;

        return finalAmount;
      } else {
        return 0;
      }
    } else {
      // Flat Discount
      return this.previewAmount + this.previewTravelFee - this.previewDiscount;
    }
  }

  get previewStripeFee() {
    let fees = this.previewSubtotal / 0.971 + 0.3 - this.previewSubtotal;
    return this.previewSubtotal == 0 ? 0 : parseFloat(fees);
  }

  get previewTotal() {
    let total = this.previewSubtotal + this.previewStripeFee;
    return total == 0 ? 0 : parseFloat(total);
  }

  // Preview Displays
  get displayPreviewAmount() {
    return Accounting.formatMoney(this.previewAmount);
  }

  get displayPreviewCommissionFee() {
    return Accounting.formatMoney(this.previewCommissionFee);
  }

  get displayPreviewPlatformFee() {
    return Accounting.formatMoney(this.previewPlatformFee);
  }

  get displayPreviewTravelFee() {
    return Accounting.formatMoney(this.previewTravelFee);
  }

  get displayPreviewSubtotal() {
    return Accounting.formatMoney(this.previewSubtotal);
  }

  get displayPreviewDiscount() {
    return Accounting.formatMoney(this.previewDiscount);
  }

  get displayPreviewStripeFee() {
    return Accounting.formatMoney(this.previewStripeFee);
  }

  get displayPreviewTotal() {
    return Accounting.formatMoney(this.previewTotal);
  }

  // Finance Displays
  get displayAmount() {
    return Accounting.formatMoney(this.amount);
  }

  get displayCommissionFee() {
    return Accounting.formatMoney(this.providerCommissionAmount);
    // return Accounting.formatMoney(this.commissionFee);
  }

  get displayPlatformFee() {
    return Accounting.formatMoney(this.platformFee);
  }

  get displayTravelFee() {
    return Accounting.formatMoney(this.travelFee);
  }

  get displaySubtotal() {
    return Accounting.formatMoney(this.subtotal);
  }

  get displayDiscount() {
    return Accounting.formatMoney(this.discount);
  }

  get displayStripeFee() {
    return Accounting.formatMoney(this.stripeFee);
  }

  get displayTotal() {
    return Accounting.formatMoney(this.total);
  }

  get displayTipAmount() {
    return Accounting.formatMoney(this.tipAmount);
  }

  get displayRefund() {
    return Accounting.formatMoney(this.refundAmount);
  }

  get url() {
    return this.user.id
      ? `/user/${this.user.id}/bookings/${this.id}`
      : `/admin/${this.provider.id}/bookings/${this.id}`;
  }

  get reviewUrl() {
    return `/review?bookingId=${this.id}`;
  }

  // Calendar
  get calendarObject() {
    let event = {};
    event.id = this.id;
    event.type = "Booking";
    event.className = "fc-booking";
    const utcMoment = Moment.utc(this.startDate);
    const mstMoment = utcMoment.tz(this?.addressBasedTimezone || "");
    const formattedDateString = mstMoment?.format("YYYY-MM-DD");

    event.start = formattedDateString; // Moment(this.startDate).format();

    const utcEndMoment = Moment.utc(this.endDate);
    const mstEndMoment = utcEndMoment.tz(this?.addressBasedTimezone || "");
    const formattedEndDateString = mstEndMoment?.format("YYYY-MM-DD");
    event.title = mstMoment?.format("h:mma")
      ? mstMoment?.format("h:mma") + " " + this.customer?.name
      : this.customer?.name;
    event.end = formattedEndDateString; //Moment(this.endDate).format();
    return event;
  }

  // Cancellations
  get cancelMsg() {
    if (this.cancellationPolicy == "full") {
      return "charge them the full amount since we're within 1 hour";
    }
    if (this.cancellationPolicy == "partial") {
      return "charge them 50% of the booking since we're within 24 hours";
    }
    return "won't charge them since we're outside 24 hours";
  }

  // Functions
  statusMsg(status) {
    switch (status) {
      case "draft":
        return `This is perfect for bookings you're still working on`;

      case "request":
        return `This will move the booking back into a request state awaiting your action`;

      case "accept":
        return `This will notify your customer and lock them in`;

      case "deny":
        return `This will notify your customer and ask them to booking for another time`;

      case "finish":
        return `This will notify your customer and charge them ${this.displayTotal} for the outstanding balance`;

      case "cancel":
        return `This will notify your customer and ${this.cancelMsg}`;

      case "delay":
        return `This will notify the customer that you are running late`;

      case "transit":
        return `This will notify the customer that you are on your way`;
    }
  }

  updateStartDate(date) {
    this.set("startDate", date);
    this.set("isUpdate", true);
    this.updateEndDate();
  }

  updateEndDate() {
    this.set("endDate", Moment(this.startDate).add(this.calcDuration, "m"));
  }
}

export default Booking;
