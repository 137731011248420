import AppModel from "models/app";

class ReferralPoints extends AppModel {
  constructor(type, store, props = {}) {
    super(type, store, props);

    // Attrs
    this.kangarooPoints = props.kangarooPoints;
  }
}

export default ReferralPoints;
