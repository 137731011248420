import React, { Component } from "react";
import { withStore } from "contexts/store-context";
import { withToast } from "contexts/toast-context";
import { ProviderLineCard } from "components/provider/provider-card";
import ServiceList from "components/service/service-list";
import GoogleMapWrapper from "components/vendors/google-map-wrapper";
import BookingFinancePreview from "components/booking/booking-finance-preview";
import FAWrapper from "components/vendors/fa-wrapper";
import { CartTitle } from "components/blocks/cart-blocks";
import {
  PreviewBlock,
  PreviewTitle,
  PreviewBody,
  PreviewFooter,
} from "components/blocks/preview-blocks";
import { pushRelation } from "utils/store-helpers";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

const BookingFlowPreview = (props) => {
  const {
    booking = {},
    store,
    toast,
    changeStep,
    prevAction,
    nextAction,
    availableReferralPoints = 0,
    onReferralSelect = () => {},
  } = props;

  // Methods
  const pushService = (serviceID) => {
    let service = store.peekRecord("service", serviceID);
    pushRelation(booking, "services", service);
  };

  // Render
  return (
    <PreviewBlock className="b">
      <CartTitle className="sm flex-between">
        <h4 className="bold">Your Order</h4>
        <div className="btn btn-outline-gray">USD</div>
      </CartTitle>
      <PreviewBody className="pl-15 pr-15">
        <PreviewBlock className="bb">
          {booking.services && (
            <ServiceList
              type="line"
              services={booking.services}
              showAction={false}
              orderSummary={props?.orderSummary}
              booking={booking}
            />
          )}
        </PreviewBlock>

        <PreviewBlock className="no-b">
          <PreviewBody>
            <BookingFinancePreview
              type="preview"
              booking={booking}
              hideReferralPoint={true}
              availableReferralPoints={availableReferralPoints}
              onReferralSelect={onReferralSelect}
            />
          </PreviewBody>
        </PreviewBlock>
      </PreviewBody>
    </PreviewBlock>
  );
};

export default withStore(withToast(BookingFlowPreview));
