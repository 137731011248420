const fontAwesome = require('@fortawesome/fontawesome-svg-core');

import {
	faChartLine,
	faChartPieAlt,
	faCheck,
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faChevronUp,
	faCalendar,
	faClock,
	faComments,
	faCrown,
	faCut,
	faExternalLink,
	faExclamationTriangle,
	faIdCard,
	faLifeRing,
	faImage,
	faLock,
	faMapMarker,
	faPiggyBank,
	faPlus,
	faQuestionCircle,
	faRepeatAlt,
	faSearch,
	faShoppingCart,
	faSignOut,
	faSort,
	faSortUp,
	faSortDown,
	faSlidersV,
	faStar,
	faTachometerAltFast,
	faTasks,
	faTimes,
	faTimesCircle,
	faTrash,
	faUniversity,
	faUser,
	faMinus,
	faCopy
} from '@fortawesome/pro-light-svg-icons';

fontAwesome.library.add(
	faCalendar,
	faCheck,
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faChevronUp,
	faClock,
	faComments,
	faCrown,
	faCut,
	faExclamationTriangle,
	faExternalLink,
	faIdCard,
	faImage,
	faLock,
	faLifeRing,
	faMapMarker,
	faPiggyBank,
	faPlus,
	faQuestionCircle,
	faRepeatAlt,
	faSearch,
	faShoppingCart,
	faSignOut,
	faSort,
	faSortUp,
	faSortDown,
	faSlidersV,
	faStar,
	faTachometerAltFast,
	faTasks,
	faTimes,
	faTimesCircle,
	faTrash,
	faUniversity,
	faUser,
	faMinus,
	faCopy
)

export default fontAwesome;
