import React from "react";
import { withRouter, Link } from "react-router-dom";
import { withUser } from "contexts/user-context";
import Head from "components/basics/head";
import MktAnnouncement from "components/mkt/mkt-announcement";
import MktNav from "components/navs/mkt-nav";
import AppFooter from "components/app/app-footer";
import { UserDetailCard } from "components/user/user-card";
import BookingSection from "components/booking/booking-section";
import {
  Alert,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { withToast } from "contexts/toast-context";
import { useEffect } from "react";
import { withStore } from "contexts/store-context";
import { useState } from "react";

const UserBookingsIndex = (props) => {
  const { userId, user, toast, store } = props;

  return (
    <div className="route">
      <Head title={"Blush - " + user.name} />
      <MktAnnouncement />
      <MktNav />

      <main className="app-main">
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>User</BreadcrumbItem>
          </Breadcrumb>

          <Row>
            <Col md="4">
              <UserDetailCard
                user={user}
                toast={toast}
                showReferralPoint={true}
              />
            </Col>

            <Col md="8">
              <BookingSection
                sortProp="id"
                sortValue="desc"
                addNew={true}
                include="user,location,services"
              />
            </Col>
          </Row>
        </Container>
      </main>

      <AppFooter />
    </div>
  );
};

export default withStore(withUser(withToast(UserBookingsIndex)));
